<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="transferCompanies" />
        <ASuccessWithoutReload :api="this.api" @close="() => {
            this.api.isSuccesful = false;
        }" />
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <v-data-table v-if="!api.isLoading && data != null" :items="data" :headers="dataHeader" :search="search"
            class="elevation-1 mt-4">
            <template v-slot:item.Index="{ item }">
                {{ data.indexOf(item) + 1 }}
            </template>
            <template v-slot:top>
                <v-toolbar width="auto" height="auto" class="py-3" flat>
                    <v-row no-gutters>
                        <v-col class="mt-3 mx-3">
                            <v-row class="mb-3">
                                <v-toolbar-title class="font-weight-bold text-h4 my-3">
                                    HRDC
                                </v-toolbar-title>
                                <v-spacer>
                                </v-spacer>
                        
                                <v-btn color="primary" @click="fetch()">
                                    Refresh
                                </v-btn>
                                <JsonCSV :data="data" class="mx-2">
                                    <v-btn color="primary">
                                        Download
                                    </v-btn>
                                </JsonCSV>
                                <v-btn color="primary"  @click="validateInput" :loading="api.isLoading">
                                    Transfer
                                </v-btn>
                            </v-row>
                            <v-row>
                                <v-text-field v-model="search" dense outlined label="Search">
                                </v-text-field>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-toolbar>
            </template>
            <template v-slot:item.year_1_hrdc_invoice_sent_date="{ item }">
                <span v-if="item.year_1_hrdc_invoice_sent_date != null">
                    {{ convertTimeZone(item.year_1_hrdc_invoice_sent_date) }}
                </span>
                <span v-else>
                    -
                </span>
            </template>
            <template v-slot:item.year_2_hrdc_invoice_sent_date="{ item }">
                <span v-if="item.year_2_hrdc_invoice_sent_date != null">
                    {{ convertTimeZone(item.year_2_hrdc_invoice_sent_date) }}
                </span>
                <span v-else>
                    -
                </span>
            </template>
            <template v-slot:item.year_3_hrdc_invoice_sent_date="{ item }">
                <span v-if="item.year_3_hrdc_invoice_sent_date != null">
                    {{ convertTimeZone(item.year_3_hrdc_invoice_sent_date) }}
                </span>
                <span v-else>
                    -
                </span>
            </template>
        </v-data-table>

    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AConfirmation from '../../common/AConfirmation.vue';
import ASuccessWithoutReload from '../../common/ASuccessWithoutReload.vue';
export default {
    components: {
    AConfirmation,
    ASuccessWithoutReload
},
    computed: mapState({
        //
    }),
    props: [
        //
    ],
    data() {
        return {
            data: null,
            search: '',
            dataHeader: [
                { text: '#', value: 'Index' },
                { text: 'Company Name', value: 'company_name' },
                { text: 'Excel Key', value: 'excel_key' },
                { text: 'HRDC Invoice', value: 'year_1_hrdc_invoice' },
                { text: 'HRDC Invoice Date', value: 'year_1_hrdc_invoice_sent_date' },
                { text: 'HRDC Invoice (Renewal - Year 2)', value: 'year_2_hrdc_invoice' },
                { text: 'HRDC Invoice (Renewal - Year 2) Sent Date', value: 'year_2_hrdc_invoice_sent_date' },
                { text: 'HRDC Invoice (Renewal - Year 3)', value: 'year_3_hrdc_invoice' },
                { text: 'HRDC Invoice (Renewal - Year 3) Sent Date', value: 'year_3_hrdc_invoice_sent_date' },
            ],
            isPending: false,
            api: {
                isLoading: false,
                isError: false,
                error: null,
                url: null,
                isSuccesful: false,
                success: null,
            },
        };
    },
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };

        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };

        this.api.callbackSuccess = (resp) => {
            if (resp.class === "getCompaniesHRDC") {
                this.data = resp.data;
            }
            if (resp.class == 'transferCompanyHRDC') {
                this.api.success = "Succesfully transfer data";
                this.api.isSuccesful = true;
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            let fetchHRDCApi = this.fetchHRDC();
            this.$api.fetch(fetchHRDCApi);
        },
        fetchHRDC() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/developer/excel/hrdc";
            return tempApi;
        },
        transferCompanies() {
            this.isPending = false;
            let transferCompaniesApi = this.transferCompaniesApi();
            this.$api.fetch(transferCompaniesApi);
        },
        transferCompaniesApi() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/hrdc/transfer";
            return tempApi;
        },
        validateInput() {
            this.isPending = true;
        },
        cancelSubmit() {
            this.isPending = false;
        },
        submit() {
            this.isPending = false;
        },
        convertTimeZone(time) {
            return this.$moment(time).format("LL");
        },
    }
}
</script>